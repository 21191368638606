<template>
    <div id="app" class="animated fadeIn">
        <b-card>
            <div slot="header">
                <strong style="margin-left: 5px">Select your Data Source</strong>
            </div>

            <div v-for="(collects, category) in groupby(filteredList)" :key="category">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item active" aria-current="page">{{ category }}</li>
                    </ol>
                </nav>

                <div class="wrapper">
                    <div
                        class="source-card"
                        v-for="collect in collects"
                        :key="collect.name"
                        v-bind:class="{
                            'source-card-disabled': !collect.isAllowed,
                        }"
                    >
                        <div
                            v-if="!collect.isAllowed"
                            class="source-card-content"
                            v-b-tooltip.hover="'Not available. Please contact us if you are interested in this module'"
                        >
                            <img :src="getImgUrl(collect.image)" />
                            <p class="source-card-name">{{ collect.name }}</p>
                        </div>

                        <project-router-link v-else :to="collect.link">
                            <img :src="getImgUrl(collect.image)" />
                            <p class="source-card-name">{{ collect.name }}</p>
                        </project-router-link>
                    </div>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    data() {
        return {
            filteredList: [],
            list: [
                {
                    category: 'User Behaviour',
                    image: 'logotypes/analytics_36.svg',
                    name: 'Google Analytics 4 BigQuery',
                    link: '/collect/analytics4bq/create',
                },

                {
                    category: 'User Behaviour',
                    image: 'logotypes/search_console_36.svg',
                    name: 'Search Console',
                    link: '/collect/searchConsole/create',
                },
                {
                    category: 'Ad Systems',
                    image: 'logotypes/google_ads_36.svg',
                    name: 'Google Ads',
                    link: '/collect/googleads/create',
                },
                {
                    category: 'Ad Systems',
                    image: 'logotypes/facebook_36.svg',
                    name: 'Facebook',
                    link: '/collect/facebook/create',
                },
                {
                    category: 'Ad Systems',
                    image: 'logotypes/criteo_36.svg',
                    name: 'Criteo',
                    link: '/collect/criteo/create',
                },
                {
                    category: 'Ad Systems',
                    image: 'logotypes/tradedoubler_36.svg',
                    name: 'Tradedoubler',
                    link: '/collect/tradedoubler/create',
                },
                {
                    category: 'Ad Systems',
                    image: 'logotypes/rtbhouse_36.svg',
                    name: 'RTB House',
                    link: '/collect/rtb/create',
                },
                {
                    category: 'Ad Systems',
                    image: 'logotypes/optimise_36.svg',
                    name: 'Optimise',
                    link: '/collect/optimise/create',
                },
                {
                    category: 'Ad Systems',
                    image: 'logotypes/awin_36.svg',
                    name: 'Awin',
                    link: '/collect/awin/create',
                },
                {
                    category: 'Ad Systems',
                    image: 'logotypes/domodi_36.svg',
                    name: 'Domodi & Allani',
                    link: '/collect/domodi/create',
                },
                {
                    category: 'Ad Systems',
                    image: 'logotypes/convertiser.svg',
                    name: 'Convertiser',
                    link: '/collect/convertiser/create',
                },
                {
                    category: 'Ad Systems',
                    image: 'logotypes/tiktok.svg',
                    name: 'TikTok',
                    link: '/collect/tiktok/create',
                },
                {
                    category: 'Ad Systems',
                    image: 'logotypes/ceneo_36.svg',
                    name: 'Ceneo',
                    link: '/collect/ceneo/create',
                },
                {
                    category: 'Ad Systems',
                    image: 'logotypes/bing.svg',
                    name: 'Bing Ads',
                    link: '/collect/bing/create',
                },
                {
                    category: 'Ecommerce',
                    image: 'logotypes/shoper_36.svg',
                    name: 'Shoper',
                    link: '/collect/shoper/create',
                },
                {
                    category: 'Ecommerce',
                    image: 'logotypes/baselinker_36.svg',
                    name: 'BaseLinker',
                    link: '/collect/baselinker/create',
                },
                {
                    category: 'Ecommerce',
                    image: 'logotypes/shopify_36.svg',
                    name: 'Shopify',
                    link: '/collect/shopify/create',
                },
                {
                    category: 'Ecommerce',
                    image: 'logotypes/magento_36.svg',
                    name: 'Magento',
                    link: '/collect/magento/create',
                },
                {
                    category: 'Ecommerce',
                    image: 'logotypes/prestashop_36.svg',
                    name: 'PrestaShop',
                    link: '/collect/prestashop/create',
                },
                {
                    category: 'Ecommerce',
                    image: 'logotypes/idosell.svg',
                    name: 'IdoSell',
                    link: '/collect/idosell/create',
                },
                {
                    category: 'Ecommerce',
                    image: 'logotypes/woocommerce.svg',
                    name: 'Woo Commerce',
                    link: '/collect/woocommerce/create',
                },
                {
                    category: 'Marketplace',
                    image: 'logotypes/allegro.svg',
                    name: 'Allegro',
                    link: '/collect/allegro/create',
                },
                {
                    category: 'CRM',
                    image: 'logotypes/hubspot_36.svg',
                    name: 'HubSpot',
                    link: '/collect/hubspot/create',
                },
                {
                    category: 'Other Integrations',
                    image: 'logotypes/cost_36.svg',
                    name: 'Custom Data',
                    link: '/collect/customData/create',
                },
                {
                    category: 'Other Integrations',
                    image: 'logotypes/currency_36.svg',
                    name: 'Currency Converters',
                    link: '/collect/currencyConverters/create',
                },
                {
                    category: 'Other Integrations',
                    image: 'logotypes/doc_36.svg',
                    name: 'Data stream',
                    link: '/collect/datastream/create',
                },
            ],
        }
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
        }),
    },
    async created() {
        const list = this.list.filter((children) => {
            return children.name.toLowerCase()
        })

        const response = await this.axios.get(`${process.env.VUE_APP_NODE_API_HOST}/modules-filtering`)
        const allowList = response.data.data
        const projectId = this.activeProject.id

        const modules = list.map((module) => {
            const {category, name} = module
            const slug = [category, name].join('-').replace(/ /g, '-').replace(/&/g, 'and').toLowerCase()

            const moduleInAllowList = allowList.find((module) => module.id === slug)
            const isAllowed = Boolean(
                moduleInAllowList &&
                    (moduleInAllowList.includes.find(({id}) => id === projectId) ||
                        moduleInAllowList.includes.length === 0)
            )

            return {
                ...module,
                isAllowed,
            }
        })

        this.filteredList = modules
    },
    methods: {
        getImgUrl(pic) {
            return require('@/assets/' + pic)
        },
        groupby(array) {
            return array.reduce(
                (h, obj) =>
                    Object.assign(h, {
                        [obj.category]: (h[obj.category] || []).concat(obj),
                    }),
                {}
            )
        },
    },
}
</script>

<style lang="scss">
div#app {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .search-wrapper {
        position: relative;
        label {
            position: absolute;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.5);
            top: 8px;
            left: 12px;
            z-index: -1;
            transition: 0.15s all ease-in-out;
        }
        input {
            padding: 4px 12px;
            color: rgba(0, 0, 0, 0.7);
            border: 1px solid rgba(0, 0, 0, 0.12);
            transition: 0.15s all ease-in-out;
            background: white;
            &:focus {
                outline: none;
                transform: scale(1.05);
                & + label {
                    font-size: 10px;
                    transform: translateY(-24px) translateX(-12px);
                }
            }
            &::-webkit-input-placeholder {
                font-size: 12px;
                color: rgba(0, 0, 0, 0.5);
                font-weight: 100;
            }
        }
    }

    .card {
        width: 100%;
    }

    .wrapper {
        display: flex;
        flex-wrap: wrap;
        padding-top: 12px;
    }

    .collect {
        box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
        margin: 12px;
        transition: 0.15s all ease-in-out;

        a {
            text-decoration: none;
            padding: 12px;
            color: #03a9f4;
            font-size: 24px;
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
                height: 100px;
            }
            small {
                font-size: 10px;
                padding: 4px;
            }
        }
    }
}
</style>
